<template>
  <div class="component-slider">
    <Carousel :circular="true" :autoplayInterval="8000" :value="imagesArray" :numVisible="1" :numScroll="1"
    class="dots-center-right no-dots">
      <template #item="image">
        <span v-html="image.data"></span>
      </template>
    </Carousel>
  </div>
</template>
<script>
import Carousel from 'primevue/carousel'

export default {
  name: 'Slider',
  props: {
    imagesArray: {
      type: Array,
      default () {
        return ['<img src="https://via.placeholder.com/1920x440?text=adomicilio cover" alt="Adomicilio">']
      }
    }
  },
  components: {
    Carousel
  }
}

</script>
