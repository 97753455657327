<template>
  <form class="component-delivery">
    <label class="methods mr-1">
      <Dropdown v-model="selectedMethod" :options="methods" optionLabel="name"
                placeholder="Elige tu Método de Entrega"/>
    </label>
    <label class="address icon-left mr-1">
      <AutoComplete v-model="selectedAddress" :suggestions="filteredAddresses" @complete="searchAddress($event)"
                    field="label" placeholder="Ingresa tu dirección">
        <template #item="prop">
          <div>{{ prop.item.label }}</div>
        </template>
      </AutoComplete>
      <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.49999 0.916748C2.75874 0.916748 0.541656 3.13383 0.541656 5.87508C0.541656 9.59383 5.49999 15.0834 5.49999 15.0834C5.49999 15.0834 10.4583 9.59383 10.4583 5.87508C10.4583 3.13383 8.24124 0.916748 5.49999 0.916748ZM5.49999 7.64592C4.52249 7.64592 3.72916 6.85258 3.72916 5.87508C3.72916 4.89758 4.52249 4.10425 5.49999 4.10425C6.47749 4.10425 7.27082 4.89758 7.27082 5.87508C7.27082 6.85258 6.47749 7.64592 5.49999 7.64592Z"
          fill="#747474"/>
      </svg>
    </label>
    <button>Aceptar</button>
  </form>
</template>
<script>
import Dropdown from 'primevue/dropdown'
import AutoComplete from 'primevue/autocomplete'

export default {
  name: 'Delivery',
  components: {
    Dropdown,
    AutoComplete
  },
  data () {
    return {
      selectedMethod: null,
      selectedAddress: null,
      filteredAddresses: [],
      methods: [
        {
          name: 'A Domicilio',
          code: 'DOM'
        },
        {
          name: 'Recoger',
          code: 'REC'
        }
      ]
    }
  },
  addressService: null,
  created () {
    this.addressService = [
      {
        label: 'Dirección Ejemplo 1',
        value: 'D1'
      },
      {
        label: 'Dirección Ejemplo 2',
        value: 'D2'
      }
    ]
  },
  methods: {
    searchAddress (event) {
      this.filteredAddresses = []
      this.addressService.forEach((item) => {
        if (item.label.toLowerCase().includes(event.query.toLowerCase())) {
          this.filteredAddresses.push(item)
        }
      })

      console.log(this.filteredAddresses)
    }
  }
}
</script>
