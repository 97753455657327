<template>
  <Dialog v-model:visible="visible" class="product-dialog">
    <!-- Slide Images -->
    <Carousel class="relative product-images no-dots" :value="product.images" :numVisible="1" :numScroll="1">
      <template #item="image">
        <span v-html="image.data"></span>
      </template>
    </Carousel>
    <!-- Content -->
    <div class="container-padding color-60">
      <h1 class="pt-1 color-secondary">Producto Irreal</h1>
      <div class="flex align-center">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M22 11L19.56 8.21L19.9 4.52L16.29 3.7L14.4 0.5L11 1.96L7.6 0.5L5.71 3.69L2.1 4.5L2.44 8.2L0 11L2.44 13.79L2.1 17.49L5.71 18.31L7.6 21.5L11 20.03L14.4 21.49L16.29 18.3L19.9 17.48L19.56 13.79L22 11ZM9.09 15.72L5.29 11.91L6.77 10.43L9.09 12.76L14.94 6.89L16.42 8.37L9.09 15.72Z" fill="#F36E44"/>
        </svg>
        <p class="bg-label mt-0 ml-05">Combo</p>
      </div>
      <p class="alt">110 g Pastor vegetariano (producto a base de soya y gluten) servida sobre una cama de queso oaxaca
        fundido en una
        tortilla de harina Los complementos cebolla, cilantro y piña se envían por separado.</p>
      <div class="flex justify-space-between sm-direction-column mt-1">
        <InputNumber
          buttonLayout="horizontal" decrementButtonClass="btn decrement" incrementButtonClass="btn increment"
          showButtons v-model="valueQuantity" :step="1"/>
        <h2 class="color-secondary">Subtotal: $138.00</h2>
      </div>
    </div>
    <div class="options mt-2">
      <Accordion>
        <AccordionTab>
          <template #header>
            <div class="flex align-center justify-space-between">
              <div class="left">
                <p class="label mb-0 color-60">Number Input</p>
                <p class="color-40 min mb-0">obligatorio</p>
              </div>
              <div class="right">
                <button class="btn-white-primary btn-icon">
                  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1.41 0.589844L6 5.16984L10.59 0.589844L12 1.99984L6 7.99984L1.68141e-08 1.99984L1.41 0.589844Z"
                      fill="#F36E44"/>
                  </svg>
                </button>
              </div>
            </div>
          </template>
          <div class="content">
            <div class="item flex justify-space-between">
              <div class="left flex align-center">
                <InputNumber
                  buttonLayout="horizontal" decrementButtonClass="btn decrement" incrementButtonClass="btn increment"
                  showButtons v-model="option1" :step="1"/>
                <p class="ml-1 mb-0 alt color-40">Chiles toreados</p>
              </div>
              <p class="alt color-20">+ 25 MXN</p>
            </div>
          </div>
        </AccordionTab>
      </Accordion>
      <Accordion>
        <AccordionTab>
          <template #header>
            <div class="flex align-center justify-space-between">
              <div class="left">
                <p class="label mb-0 color-60">Checkbox Input</p>
                <p class="color-40 min mb-0">1 obligatorio</p>
              </div>
              <div class="right">
                <button class="btn-white-primary btn-icon">
                  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1.41 0.589844L6 5.16984L10.59 0.589844L12 1.99984L6 7.99984L1.68141e-08 1.99984L1.41 0.589844Z"
                      fill="#F36E44"/>
                  </svg>
                </button>
              </div>
            </div>
          </template>
          <div class="content">
            <div class="item flex direction-row justify-space-between">
              <label class="flex direction-row align-center alt mb-2 alt color-40">
                <Checkbox class="mr-1" name="op1" value="Option1" v-model="option2"/>
                <span>Sin cebolla</span>
              </label>
              <p class="alt color-20">+ 25 MXN</p>
            </div>
            <div class="item flex justify-space-between">
              <label class="flex direction-row  align-center alt mb-2 alt color-40">
                <Checkbox class="mr-1" name="op2" value="Option2" v-model="option2"/>
                <span>Sin Atún</span>
              </label>
              <p class="alt color-20">+ 35 MXN</p>
            </div>

          </div>
        </AccordionTab>
      </Accordion>
      <Accordion>
        <AccordionTab>
          <template #header>
            <div class="flex align-center justify-space-between">
              <div class="left">
                <p class="label mb-0 color-60">Checkbox Input</p>
                <p class="color-40 min mb-0">1 obligatorio</p>
              </div>
              <div class="right">
                <button class="btn-white-primary btn-icon">
                  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1.41 0.589844L6 5.16984L10.59 0.589844L12 1.99984L6 7.99984L1.68141e-08 1.99984L1.41 0.589844Z"
                      fill="#F36E44"/>
                  </svg>
                </button>
              </div>
            </div>
          </template>
          <div class="content">
            <div class="item flex justify-space-between">
              <label class="flex direction-row align-center alt mb-2 alt color-40">
                <RadioButton class="mr-1" name="opr" value="Option1" v-model="option2"/>
                PepsiCola
              </label>
              <p class="alt color-20">+ 25 MXN</p>
            </div>
            <div class="item flex justify-space-between">
              <label class="flex direction-row align-center alt mb-2 alt color-40">
                <RadioButton class="mr-1" name="opr" value="Option2" v-model="option2"/>
                CocaCola
              </label>
              <p class="alt color-20">+ 25 MXN</p>
            </div>
          </div>
        </AccordionTab>
      </Accordion>
      <div class="note">
        <div class="header bg-color-10 container-padding pt-2 pb-2 sm-pb-1">
          <p class="label color-60">Agregar una nota</p>
        </div>
        <div class="grid-columns-1 pt-2 pb-2 sm-pt-1 container-padding content">
          <label class="icon-top">
            <Textarea placeholder="Comentarios de la orden" v-model="option4" rows="5" cols="100"/>
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.167 0.416992H1.83366C1.05449 0.416992 0.424076 1.05449 0.424076 1.83366L0.416992 14.5837L3.25033 11.7503H13.167C13.9462 11.7503 14.5837 11.1128 14.5837 10.3337V1.83366C14.5837 1.05449 13.9462 0.416992 13.167 0.416992ZM3.95866 5.37533H11.042C11.4316 5.37533 11.7503 5.69408 11.7503 6.08366C11.7503 6.47324 11.4316 6.79199 11.042 6.79199H3.95866C3.56908 6.79199 3.25033 6.47324 3.25033 6.08366C3.25033 5.69408 3.56908 5.37533 3.95866 5.37533ZM8.20866 8.91699H3.95866C3.56908 8.91699 3.25033 8.59824 3.25033 8.20866C3.25033 7.81908 3.56908 7.50033 3.95866 7.50033H8.20866C8.59824 7.50033 8.91699 7.81908 8.91699 8.20866C8.91699 8.59824 8.59824 8.91699 8.20866 8.91699ZM11.042 4.66699H3.95866C3.56908 4.66699 3.25033 4.34824 3.25033 3.95866C3.25033 3.56908 3.56908 3.25033 3.95866 3.25033H11.042C11.4316 3.25033 11.7503 3.56908 11.7503 3.95866C11.7503 4.34824 11.4316 4.66699 11.042 4.66699Z" fill="#747474"/>
            </svg>

          </label>
        </div>
      </div>
    </div>
    <div class="last flex pb-4">
      <button class="ml-a mr-a align-center justify-space-between" @click="setProductVisibility(false)">
        <span class="clean flex align-center">
        <svg class="mr-1" width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14 4H12C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4H2C0.9 4 0 4.9 0 6V18C0 19.1 0.9 20 2 20H14C15.1 20 16 19.1 16 18V6C16 4.9 15.1 4 14 4ZM6 8C6 8.55 5.55 9 5 9C4.45 9 4 8.55 4 8V6H6V8ZM8 2C9.1 2 10 2.9 10 4H6C6 2.9 6.9 2 8 2ZM12 8C12 8.55 11.55 9 11 9C10.45 9 10 8.55 10 8V6H12V8Z"
            fill="white"/>
        </svg>
          Agregar al Carrito</span><span class="clean">$100.00</span></button>
    </div>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog'
import Carousel from 'primevue/carousel'
import InputNumber from 'primevue/inputnumber'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Checkbox from 'primevue/checkbox'
import RadioButton from 'primevue/radiobutton'
import Textarea from 'primevue/textarea'

export default {
  name: 'Product',
  data () {
    return {
      visible: false,
      product: {
        images: [
          '<img src="https://via.placeholder.com/800x500?text=adomicilio1" alt="Adomicilio">',
          '<img src="https://via.placeholder.com/800x500?text=adomicilio2" alt="Adomicilio">'
        ]
      },
      valueQuantity: 1,
      option1: 1,
      option2: [],
      option3: null,
      option4: ''
    }
  },
  methods: {
    setProductVisibility (val = true) {
      this.visible = val
    }
  },
  components: {
    Dialog,
    Carousel,
    InputNumber,
    Accordion,
    AccordionTab,
    RadioButton,
    Checkbox,
    Textarea
  }
}

</script>
