<template>
  <Dialog v-model:visible="visible" class="onboarding-dialog">
    <div class="item" v-if="slideActive === 0">
      <div class="grid-columns-2 sm-full">
        <img src="https://via.placeholder.com/420x445?text=adomicilio" alt="A domicilio">
        <div class="color-secondary mb-2">
          <h2 class="h1 pt-1">Bienvenido a <br>Taquería El Califa</h2>
          <p class="alt mb-1">Un taco lo hace cualquiera, pero un taco en El Califa es toda una experiencia.</p>
          <p class="alt mb-1">Ven y comprueba porque el taco es lo nuestro. Te invitamos a deleitarte con nuestro menú y
            probar lo que es un buen taco.</p>
          <p class="alt">¡Descubre todo lo que tenemos para ti!</p>
          <button class="mt-1" @click="slideActive++">Continuar</button>
        </div>
      </div>
    </div>
    <div class="item" v-if="slideActive === 1">
      <div class="grid-columns-2 sm-full">
        <img src="https://via.placeholder.com/420x445?text=adomicilio" alt="A domicilio">
        <div class="color-secondary mb-2">
          <h2 class="h1 pt-1">¡Crea tu cuenta y disfruta! </h2>
          <p class="alt mb-1">Crea tu cuenta y comienza a disfrutar de todos los beneficios de pedir en nuestro
            restaurante. </p>
          <p class="alt mb-1">Al crear tu cuenta podrás elegir tus productos favoritos y pedirlos a la puerta de tu
            hogar,
            oficina, o en el lugar en que te encuentres.</p>
          <p class="alt">Ven y comprueba porque el taco es lo nuestro.</p>
          <button class="mt-1" @click="visible = false">Aceptar</button>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog'
import Carousel from 'primevue/carousel'

export default {
  name: 'Onboarding',
  data () {
    return {
      slideActive: 0,
      visible: true,
      slides: []
    }
  },
  methods: {
    setOnboardingVisibility (val = true) {
      this.visible = val
    }
  },
  components: {
    Dialog
  }
}

</script>
