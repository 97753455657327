<template>
  <section class="home-cover">
    <delivery></delivery>
    <Slider v-bind:images-array="this.imagesCover" class="home-slider"></Slider>
  </section>

  <section class="container-box pt-2 pb-2">
    <h1 class="color-secondary">Sugerencias</h1>
    <div class="grid-columns-4 mb-4">
      <a-card v-for="n in 10" v-bind:key="n" v-bind:item="this.dish"/>
    </div>
  </section>

  <section class="container-box pt-2 pb-2">
    <h1 class="color-secondary">Categorias</h1>
    <div class="grid-columns-4 mb-4">
      <a-card v-for="n in 4" v-bind:key="n" v-bind:item="this.category"/>
    </div>
  </section>

  <section class="pt-2 pb-2">
    <Slider v-bind:images-array="this.imagesCover"></Slider>
  </section>

  <section class="container-box pt-2 pb-2">
    <h1 class="color-secondary">Más Vendidos</h1>
    <div class="grid-columns-3 mb-4">
      <a-card v-for="n in 9" v-bind:orientation="'horizontal'" v-bind:key="n" v-bind:item="this.dish"
              v-on:visible="this.setProductVisibility($event)"/>
    </div>
  </section>

  <section class="container-box pt-2 pb-2 mt-2 mb-2 text-center sandwich">
    <hr class="min">
    <h2 class="color-primary">¿Deseas ponerte en contacto con nosotros?</h2>
    <RouterLink class="btn inline-flex" to="/contact">Ir a Contacto</RouterLink>
    <hr class="min mt-2">
  </section>
  <Product ref="productComponent"/>
  <template v-if="this.isFirstTime">
    <onboarding/>
  </template>
  <Dialog v-model:visible="this.loginAdvice" class="small text-center">
    <h2 class="h1 mb-0 color-secondary-on text-center">Antes de continuar...</h2>
    <img class="mt-2 mb-2" src="https://via.placeholder.com/200x200?text=adomicilio1" alt="Adomicilio">
    <p class="alt color-secondary mb-2">Para poder agregar a favoritos te invitamos a registrarte <br>
      ¿Deseas hacerlo de una vez?</p>
    <p class="text-center">
      <router-link class="btn" to="/sign-in">Si, registrarme</router-link>
    </p>
    <p class="text-center">
      <button class="btn-clean color-60" @click="this.loginAdvice= false"><u>Cancelar</u></button>
    </p>
  </Dialog>
</template>
<script>

import { Options, Vue } from 'vue-class-component'
import Slider from '@/components/Slider'
import Delivery from '@/components/Delivery'
import ACard from '@/components/Card'
import Product from '@/components/Product'
import Onboarding from '@/components/Onboarding'
import Dialog from 'primevue/dialog'

@Options({
  data () {
    return {
      loginAdvice: false,
      isFirstTime: false,
      productVisible: false,
      imagesCover: [
        '<img src="https://via.placeholder.com/1920x760?text=adomicilio1" alt="Adomicilio">',
        '<img src="https://via.placeholder.com/1920x760?text=adomicilio2" alt="Adomicilio">'
      ],
      dish: {
        name: 'Nombre del Platillo',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab consequuntur magni.',
        price: 50,
        favButton: true
      },
      category: {
        name: 'Nombre de Categoria',
        favButton: false,
        description: '',
        price: 0
      }
    }
  },
  components: {
    Slider,
    Delivery,
    ACard,
    Product,
    Onboarding,
    Dialog
  },
  methods: {
    setProductVisibility (val = true) {
      this.$refs.productComponent.setProductVisibility(val)
    }
  }
})
export default class Home extends Vue {

}

</script>

<style lang="scss">
.home-cover {

  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:before {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $opacityGradient;
  }

  .component-delivery {
    left: 5%;
    z-index: 2;
    position: absolute;
    max-width: 90%;
  }
}

.home-slider {
  img {
    min-height: 90vh;
    object-fit: cover;
  }
}
</style>
